import { AdditionalScreens } from "src/assets/FaceTecAngularSampleApp/src/utilities/AdditionalScreens";
import { SampleAppUtilities } from "src/assets/FaceTecAngularSampleApp/src/utilities/SampleAppUtilities";

export const FaceTecStrings = {
    FaceTec_action_ok: "OK",
    FaceTec_action_im_ready: "I'M READY",
    FaceTec_action_try_again: "TRY AGAIN",
    FaceTec_action_continue: "CONTINUE",
    FaceTec_action_take_photo: "TAKE PHOTO",
    FaceTec_action_accept_photo: "ACCEPT",
    FaceTec_action_retake_photo: "RETAKE",
    FaceTec_action_confirm: "CONFIRM INFO",

    FaceTec_accessibility_cancel_button: "Cancel",
    FaceTec_accessibility_tap_guidance: "Double tap on the screen for face alignment guidance",
    FaceTec_accessibility_key_down_guidance: "Press Enter or Spacebar for face alignment guidance",

    FaceTec_accessibility_feedback_move_phone_away: "Camera Too Close",
    FaceTec_accessibility_feedback_move_phone_closer: "Camera Too Far Away",
    FaceTec_accessibility_feedback_face_too_far_left: "Face Too Far Left",
    FaceTec_accessibility_feedback_face_too_far_right: "Face Too Far Right",
    FaceTec_accessibility_feedback_face_too_low: "Face Too Low",
    FaceTec_accessibility_feedback_face_too_high: "Face Too High",
    FaceTec_accessibility_feedback_face_rotated_too_far_left: "Face Rotated Too Far Left",
    FaceTec_accessibility_feedback_face_rotated_too_far_right: "Face Rotated Too Far Right",
    FaceTec_accessibility_feedback_face_looking_too_far_left: "Face Pointing Too Far Left",
    FaceTec_accessibility_feedback_face_looking_too_far_right: "Face Pointing Too Far Right",
    FaceTec_accessibility_feedback_face_not_in_camera: "Face Not On Camera or Too Far Away",
    FaceTec_accessibility_feedback_hold_phone_to_eye_level: "Hold Device At Eye Level",
    FaceTec_accessibility_feedback_move_away_web: "Camera Too Close",
    FaceTec_accessibility_feedback_move_closer_web: "Camera Too Far Away",
    FaceTec_accessibility_feedback_hold_to_eye_level_web: "Move Camera To Eye Level",

    FaceTec_presession_frame_your_face: "Frame Your Face In The Oval",
    FaceTec_presession_look_straight_ahead: "Look Straight Ahead",
    FaceTec_presession_hold_steady3: "Hold Steady for: 3",
    FaceTec_presession_hold_steady2: "Hold Steady for: 2",
    FaceTec_presession_hold_steady1: "Hold Steady for: 1",
    FaceTec_presession_eyes_straight_ahead: "Look Straight Ahead",
    FaceTec_presession_remove_dark_glasses: "Remove Dark Glasses",
    FaceTec_presession_neutral_expression: "Neutral Expression, No Smiling",
    FaceTec_presession_conditions_too_bright: "Conditions Too Bright",
    FaceTec_presession_brighten_your_environment: "Brighten Your Environment",

    FaceTec_feedback_center_face: "Center Your Face",
    FaceTec_feedback_face_not_found: "Frame Your Face",
    FaceTec_feedback_move_phone_away: "Move Away",
    FaceTec_feedback_move_away_web: "Move Away",
    FaceTec_feedback_move_phone_closer: "Move Closer",
    FaceTec_feedback_move_web_closer: "Move Face Closer & Fill Oval",
    FaceTec_feedback_move_web_even_closer: "Even Closer",
    FaceTec_feedback_move_phone_to_eye_level: "Move Phone To Eye Level",
    FaceTec_feedback_move_to_eye_level_web: "Look Straight Into Camera",
    FaceTec_feedback_face_not_looking_straight_ahead: "Look Straight Ahead",
    FaceTec_feedback_face_not_upright: "Hold Your Head Straight",
    FaceTec_feedback_face_not_upright_mobile: "Keep Head Straight",
    FaceTec_feedback_hold_steady: "Hold Steady",
    FaceTec_feedback_use_even_lighting: "Light Face More Evenly",

    FaceTec_instructions_header_ready_desktop: "Get Ready For Your Video Selfie",
    FaceTec_instructions_header_ready_1_mobile: "Get Ready For",
    FaceTec_instructions_header_ready_2_mobile: "Your Video Selfie",
    FaceTec_instructions_message_ready_desktop:
        "Frame Your Face in the Oval, Press I'm Ready & Move Closer",
    FaceTec_instructions_message_ready_1_mobile: "Frame Your Face in the Oval,",
    FaceTec_instructions_message_ready_2_mobile: "Press I'm Ready & Move Closer",

    FaceTec_retry_header: "Let's Try That Again",
    FaceTec_retry_subheader_message: "We Need a Clearer Video Selfie",
    FaceTec_retry_your_image_label: "Your Selfie",
    FaceTec_retry_ideal_image_label: "Ideal Pose",
    FaceTec_retry_instruction_message_1: "Please Use Ideal Pose & Lighting",
    FaceTec_retry_instruction_message_2: "Neutral Expression, No Smiling",
    FaceTec_retry_instruction_message_3: "Too Blurry, Clean Camera",

    FaceTec_camera_feed_issue_header: "Issue Securing Camera Feed",
    FaceTec_camera_feed_issue_header_mobile: "Issue Securing<br/>Camera Feed",
    FaceTec_camera_feed_issue_subheader_message_mobile:
        "This App blocks suspicious webcam configurations.<br/><a href='https://livenesscheckhelp.com/' target='_blank' style='text-decoration:underline; font:inherit; pointer-events:all;'>Learn More Here</a>.",
    FaceTec_camera_feed_issue_subheader_message:
        "This system cannot be verified due to the following:",
    FaceTec_camera_feed_issue_table_header_1: "Possible Issue",
    FaceTec_camera_feed_issue_table_header_2: "Fix",
    FaceTec_camera_feed_issue_table_row_1_cell_1_firefox_permissions_error:
        "Camera permissions not remembered in Firefox.",
    FaceTec_camera_feed_issue_table_row_1_cell_2_firefox_permissions_error:
        "Check Remember Permissions.",
    FaceTec_camera_feed_issue_table_row_1_cell_1: "Camera already in use by another App.",
    FaceTec_camera_feed_issue_table_row_1_cell_2: "Close the other App.",
    FaceTec_camera_feed_issue_table_row_2_cell_1: "A 3rd-Party App is modifying the video.",
    FaceTec_camera_feed_issue_table_row_2_cell_2: "Close/Uninstall the other App.",
    FaceTec_camera_feed_issue_table_row_3_cell_1: "Hardware not capable of being secured.",
    FaceTec_camera_feed_issue_table_row_3_cell_2: "Use a different Device.",
    FaceTec_camera_feed_issue_subtable_message:
        "This App blocks suspicious webcam configurations. <a href='https://livenesscheckhelp.com/' target='_blank' style='text-decoration:underline; font:inherit; pointer-events:all;'>Learn More Here</a>.",
    FaceTec_camera_feed_issue_action: "TRY AGAIN ANYWAY",
    FaceTec_camera_feed_issue_action_firefox_permissions_error: "OK",

    FaceTec_camera_permission_header: "Enable Camera",
    FaceTec_camera_permission_message:
        "Camera permissions disabled.<br/>Please check your operating system and browser settings.",

    FaceTec_enter_fullscreen_header: "Full Screen Selfie Mode",
    FaceTec_enter_fullscreen_message:
        "Before we begin, please click the button below to open full screen mode.",
    FaceTec_enter_fullscreen_action: "OPEN FULL SCREEN",

    FaceTec_initializing_camera: "Securing Camera Feed",

    FaceTec_idscan_type_selection_header: "Step 2/3<br/>Scan your Document",
    FaceTec_idscan_capture_id_front_instruction_message: "Show Front of ID",
    FaceTec_idscan_capture_id_back_instruction_message: "Show Back of ID",
    FaceTec_idscan_review_id_front_instruction_message: "Confirm Photo is Clear & Legible",
    FaceTec_idscan_review_id_back_instruction_message: "Confirm Photo is Clear & Legible",
    FaceTec_idscan_additional_review_message: "Additional Review<br/>Required",
    FaceTec_idscan_ocr_confirmation_main_header: "Review & Confirm",
    FaceTec_idscan_ocr_confirmation_scroll_message: "Scroll Down",

    FaceTec_result_success_message: "Success!",
    FaceTec_result_facescan_upload_message: "Uploading<br/>Encrypted<br/>3D FaceScan",
    FaceTec_result_idscan_upload_message: "Uploading<br/>Encrypted<br/>ID Document",
    FaceTec_result_idscan_unsuccess_message: "ID Photo<br/>Did Not Match<br/>User's Face",
    FaceTec_result_idscan_success_front_side_message: "ID Scan Complete",
    FaceTec_result_idscan_success_front_side_back_next_message: "Front of ID<br/>Scanned",
    FaceTec_result_idscan_success_back_side_message: "ID Scan Complete",
    FaceTec_result_idscan_success_passport_message: "Passport Scan Complete",
    FaceTec_result_idscan_success_user_confirmation_message: "Photo ID Scan<br/>Complete",
    FaceTec_result_idscan_success_additional_review_message: "ID Photo Capture<br/>Complete",
    FaceTec_result_idscan_retry_face_did_not_match_message: "Face Didn't Match<br/>Highly Enough",
    FaceTec_result_idscan_retry_id_not_fully_visible_message: "ID Document<br/>Not Fully Visible",
    FaceTec_result_idscan_retry_ocr_results_not_good_enough_message: "ID Text Not Legible",
    FaceTec_result_idscan_retry_id_type_not_suppported_message:
        "ID Type Not Supported<br/>Please Use a Different ID",
    FaceTec_result_idscan_retry_barcode_not_read_message:
        "Barcode Failed To Scan<br/>Please Try Again",
};

export const getLocalizedStrings = (locale: string) => {
    return import(`../assets/core-sdk-optional/FaceTecStrings.${locale}.js`)
};

export const isNetworkResponseServerIsOffline = (networkResponseStatus: number): boolean => {
    return networkResponseStatus >= 500;
};

export const showAdditionalScreensServerIsDown = () => {
    SampleAppUtilities.displayStatus("Server upgrade in progress. Please try again later");
    AdditionalScreens.showServerUpGradeView();
};

export const MAX_RETRIES = 3;
export const STRING_MATCH_MIN_SCORE = 50;

export enum PhotoIDNextStepEnum {
    FRONT_RETRY,
    BACK,
    BACK_RETRY,
	USER_CONFIRM,
    COMPLETE,
    NFC
}