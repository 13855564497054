import { Injectable } from '@angular/core';
import axios from 'axios';

@Injectable({
  providedIn: 'root'
})
export class SlackService {
  private webhookUrl = 'https://84e0-102-88-109-145.ngrok-free.app/log';

  constructor() { }

  sendMessage(message: any): Promise<void> {
    try {
      return axios.post(this.webhookUrl, {
        text: message
      });
    } catch (error) {
    }
  }

  // sendMessage(message: any) { }
}