export class Image {
	image: any;
	type: ImageTypeEnum;
}

export enum ImageTypeEnum {
	Portrait,
	IdPhotoFront,
	IdPhotoBack,
	IdPhotoFace,
	IdSignature,
	Signature,
	TamperingFrontEvidence,
	TamperingBackEvidence,
}

export class SavedErrors {
}

export class Data {
	ref?: string;
	sessionId: string;
	firstName: string;
	lastName: string;
	fullName: string;
	nationality: string;
	nationalityNonMRZValue: string;
	countryCode: string;
	countryCodeNonMRZValue: string;
	dateOfBirth: string;
	placeOfBirth: string;
	idNumber: string;
	idBarcode: string;
	idGroupName: string;
	dateOfIssue: string;
	dateOfExpiration: string;
	sex: string; //yes please
	address: string;
	imageList: Image[];
	userConfirmedValues: any;
	barcodeTemplateName: string;
	barcodeTemplateType: string;
	ocrTemplateName: string;
	ocrTemplateType: string;
	idPhotoMatchLevel: number;
	ageEstimate: number;
	browser: string; //userAgent
	deviceModel: string; //deviceModel
	deviceSDK: string; //deviceSDK
	platform: string; //platform
	ipAddress: string;
	missingData?: string;
	mismatchedData?: Record<string, {
		previous: string;
		current: string
	}>;
	axonExtractedData?: any;
	idNumber2: string;
}

export class Ref {
	ref: string;
}

export class Response {
	status: boolean;
	error: string;
	redirectUrl: string; //upon completion, redirect user to this url
	config: Config;
}

export class Config {
	allowedIdTypes: AllowedIdType[];
	deviceKeyIdentifier: string;
	publicFaceScanEncryptionKey: string;
	productionKeyText: string;
	browserSdkLicense: string;
	url: string;
	transactionId: string;
	token: string;
	minMatchLevel: number;
}

export class AllowedIdType {
	groupName: string;
	templateTypes: string[];
	templateNames: string[];
	additionalDocs: AdditionalDoc[];
}

export class AdditionalDoc {
	docTypeId: number;
	name: string;
}

export class Error {
	errorType: ErrorType;
	data: Data;
	submitted?: boolean;
}

export enum ErrorType {
	FaceLivenessFailed, //Not a live face
	OcrTemplateMatchFailed, // ID does not have a corresponding template 
	FaceDidNotMatchId, //Portrait and face on ID do not match
	IdTypeNotAllowed, //Id Type blocked based on Axon's server config's allowed ID Types
	IdTypeUnexpectedMedia, //Not a "live" ID Card i.e possibly a picture of a picture (moire lines)
	IdScanFailed,
	AxonMissingData,
	IdSpoofDetected,
	AxonMismatchInformation, // When both front and back data don't match
}


export interface FaceTecResponse {
	success: boolean;
	wasProcessed: boolean;
	callData: {
		tid: string;
		path: string;
		date: string;
		epochSecond: number;
		requestMethod: string;
		customID: string | null;
	};
	additionalSessionData: {
		isAdditionalDataPartiallyIncomplete: boolean;
		platform: string | null;
		appID: string | null;
		installationID: string | null;
		deviceModel: string | null;
		deviceSDKVersion: string | null;
		sessionID: string | null;
		userAgent: string | null;
		ipAddress: string | null;
	};
	error: boolean;
	serverInfo: {
		coreServerSDKVersion: string;
		customOrStandardServerSDKVersion: string;
		type: string;
		mode: string;
		notice: string;
	};
	externalDatabaseRefID: string;
	matchLevel: number;
	fullIDStatusEnumInt: number;
	digitalIDSpoofStatusEnumInt: number;
	scanResultBlob: any;
	ocrResults: {
		ocrResults: {
			scanned: {
				groups: Array<{
					fields: Array<{
						fieldKey: string;
						uiFieldType: string;
						value: string;
					}>;
					groupFriendlyName: string;
					groupKey: string;
				}>;
			};
			templateName: string;
			templateType: string;
		};
	};
	documentData: {
		scannedValues: {
			groups: Array<{
				fields: Array<{
					fieldKey: string;
					uiFieldType: string;
					value: string;
				}>;
				groupFriendlyName: string;
				groupKey: string;
			}>;
		};
		templateInfo: {
			documentCountry: string;
			documentState: string;
			templateName: string;
			templateType: string;
		};
	};
	nfcStatusEnumInt: number;
	nfcAuthenticationStatusEnumInt: number;
	barcodeStatusEnumInt: number;
	mrzStatusEnumInt: number;
	faceOnDocumentStatusEnumInt: number;
	textOnDocumentStatusEnumInt: number;
	watermarkAndHologramStatusEnumInt: number;
	matchLevelNFCToFaceMap: number;
	isCompletelyDone: boolean;
	isIDScanIncomplete: boolean;
	isReadyForUserConfirmation: boolean;
	didCompleteIDScanWithoutMatchingOCRTemplate: boolean;
	didMatchIDScanToOCRTemplate: boolean;
	scannedIDPhotoFaceFoundWithMinimumQuality: boolean;
	unexpectedMediaEncounteredAtLeastOnce: boolean;
	photoIDNextStepEnumInt: number;
	idScanAgeV2GroupEnumInt: number;
	faceMapAgeV2GroupEnumInt: number;
	idScanSessionId: string;
	idScanAgeEstimateGroupV2EnumInt: number;
	idScanAgeEstimateGroupEnumInt: number;
	faceMapAgeEstimateV2GroupEnumInt: number;
	data: any;
	didCompleteIDScanWithUnexpectedMedia: boolean | null;
	didCompleteIDScanWithoutMatching: boolean | null;
	photoIDBackCrop: string | null;
	photoIDFrontCrop: string | null;
	photoIDFaceCrop: string | null;
	photoIDTamperingEvidenceFrontImage: string | null,
	photoIDPrimarySignatureCrop: string;
	photoIDSecondarySignatureCrop: string;
	extractedNFCImage: string;
	photoIDTamperingEvidenceBackImage: string;
	axonExtractedData: {
		firstName: string;
		lastName: string;
		fullName: string;
		nationality: string;
		nationalityNonMRZValue: string | null;
		dateOfBirth: string;
		placeOfBirth: string | null;
		idNumber: string;
		idNumber2: string | null;
		idBarcode: string | null;
		dateOfIssue: string | null;
		sex: string;
		address: string;
		ageOfId: string | null;
		countryCode: string | null;
		countryCodeNonMRZValue: string | null;
		dateOfExpiration: string;
		issuingAuthority: string | null;
	};
	axonMissingInformation: boolean;
	axonMismatchInformation: boolean;
	missingData: string;
	mismatchedData: Record<string, {
		previous: string;
		current: string
	}>;
	networkError: boolean;
	digitalIDSpoofDetected: boolean;
}